<template>
  <div>
    <ItemPlan v-model="value.plan_id" />
    <ItemEnrollment v-model="value.enrollment_value" :errors="errors" />
    <div
      class="mb-2"
      v-for="(product, idx) in products"
      :key="`list-product-${idx}`"
    >
      <ItemProducts :product="product" v-model="value" :errors="errors" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "errors"],
  components: {
    ItemPlan: () => import("./ItemPlan.vue"),
    ItemEnrollment: () => import("./ItemEnrollment.vue"),
    ItemProducts: () => import("./ItemProducts.vue"),
  },
  computed: {
    data() {
      return this.$store.state.confirmReservation.data;
    },
    products() {
      _.forEach(this.data.product, (product, idx) => {
        if (product.productinstallment.length > 0) {
          let grouped = _.groupBy(
            product.productinstallment,
            "group_installment_id"
          );
          product.productinstallment = [];
          _.forEach(Object.keys(grouped), (item) => {
            product.productinstallment.push(grouped[item][0]);
          });
        }
      });
      return this.data.product;
    },
  },
};
</script>

<style>
</style>